import "@fontsource/roboto"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"
import { navigate } from "gatsby"  // navigate をインポート

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/home/") {
    navigate("/", { replace: true });  // navigate を使ってリダイレクト
  }
};
